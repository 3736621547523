/**
 * The navigation bar across the top of the application.
 */

import React, {useState} from 'react';
import Masthead, {MastheadLink, MastheadTitle,} from '@amzn/meridian/masthead';
import Text from '@amzn/meridian/text';
import {useHistory} from 'react-router-dom';


const CommonHeader = () => {
    const history = useHistory();
    const [selected, setSelected] = useState(history.location.pathname.endsWith('ABROutput') ? ('two') : 'one');

    function toABROutput() {
        history.push('/ABROutput');
        setSelected('two');
    }

    function toStations() {
        history.push('/');
        setSelected('one');
    }

    return (
        <Masthead>
            <MastheadLink selected={selected === 'one'} onClick={() => toStations()}>
                Stations
            </MastheadLink>
            <MastheadLink selected={selected === 'two'} onClick={() => toABROutput()}>
                ABR Output
            </MastheadLink>
            <MastheadTitle href="/">
                <Text fontFamily="amazonEmber" type={'h200'}>
                    Address Based Routing
                </Text>
            </MastheadTitle>
        </Masthead>
    );
};

export default CommonHeader;