import {Route, Switch} from 'react-router-dom';
import CommonHeader from './CommonHeader';
import React, {useEffect} from 'react';
import StationsPage from './stations/StationsPage';
import ABROutputPage from './abr-output/ABROutputPage';
import FileManagerPage from './file-manager/FileManagerPage';

export default function App() {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    return (
        <div>
            <CommonHeader/>
            <Switch>
                <Route exact path="/ABROutput" component={ABROutputPage}/>
                <Route path="/:Station" component={FileManagerPage}/>
                <Route exact path="" component={StationsPage}/>
            </Switch>
        </div>
    );
}