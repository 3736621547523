import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';
import Box from '@amzn/meridian/box';
import React, {ComponentState} from 'react';
import Input from '@amzn/meridian/input';
import {getAppSetting} from '../../config/AppSettings';
import axios, {AxiosResponse} from 'axios';
import ABROutputTable from './ABROutputTable';
import Alert from '@amzn/meridian/alert';
import Loader from '@amzn/meridian/loader';
import {RouteComponentProps} from 'react-router-dom';

class ABROutputPage extends React.Component<RouteComponentProps, ComponentState> {
    private readonly childRef: React.RefObject<ABROutputTable>;

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.state = {
            value: '',
            dataExceptions: [],
            invalid: false,
            searching: false,
            lastKey: '',
            pageError: false
        };

    }

    onInputKeyDown = event => {
        if (event.key === 'Enter' && this.state.lastKey !== 'Enter' && this.state.value !== '') {
            this.search();
        }
        this.setState({lastKey: event.key});
    };

    setDataExceptions(response: AxiosResponse) {
        if (this.childRef.current) {
            if (response.data !== 'Invalid plan id') {
                this.childRef.current.setState({dataExceptions: response.data.dataExceptions});
                this.setState({invalid: false});
            } else {
                this.childRef.current.setState({dataExceptions: []});
                this.setState({invalid: true});
            }
        }
        this.setState({searching: false});
    }

    failedRequest() {
        this.setState({pageError: true});
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<React.ComponentState>) {
        if (!prevState.searching && this.state.searching) {
            const apiUrl = getAppSetting('apiUrl');
            axios.get(apiUrl + '/jwt/abr-output/' + this.state.value)
                .then((response) => this.setDataExceptions(response))
                .catch(() => this.failedRequest());
        }
    }

    search() {
        this.setState({searching: true});
    }

    render() {
        const {searching, value, invalid, pageError} = this.state;

        if (pageError) {
            return (
                <Alert type={'error'}>Error loading page, try refreshing</Alert>
            );
        }

        return (
            <div>
                <Row widths={['grid-3', 'grid-6', 'grid-3']}>
                    <Column width={'grid-3'}/>
                    <Column alignmentHorizontal={'left'} spacingInset={'200'}>
                        <Heading level={1}>ABR Output</Heading>
                        <Row>
                            <Box spacingInset={'200'}>
                                <Input
                                    id="my-input"
                                    value={value}
                                    onChange={(value) => this.setState({value: value})}
                                    type="number"
                                    placeholder="Enter plan id..."
                                    onKeyDown={this.onInputKeyDown}
                                />
                            </Box>
                            {searching ? (<Loader size={'medium'}/>)
                                : invalid ? (<Alert size={'small'} type={'error'}>Invalid Plan ID</Alert>)
                                    : null}
                        </Row>
                        <ABROutputTable ref={this.childRef}/>
                    </Column>
                    <Column width={'grid-3'}/>
                </Row>
            </div>
        );
    }
}

export default ABROutputPage;