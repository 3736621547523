import React, {useCallback, useState} from 'react';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import {useHistory} from 'react-router-dom';


export default function ButtonModal(props: Props) {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const onClickButton = useCallback(() => setOpen(true), []);
    const onClickFooterButton = useCallback(() => setOpen(false), []);
    const onClose = useCallback(() => setOpen(false), []);

    return (
        <React.Fragment>
            <Button onClick={onClickButton}>{props.button}</Button>
            <Modal
                title={props.title}
                open={open}
                onClose={onClose}
                scrollContainer="viewport"
                closeLabel="Close"
            >
                <Text id="modal-description">{props.message}</Text>
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="secondary" size="small" onClick={onClickFooterButton}>
                            Cancel
                        </Button>
                        <Button type="primary" size="small" onClick={history.goBack}>
                            Confirm
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

type Props = {
    button: string
    message: string
    title: string
}