import React, {ComponentState} from 'react';
import {FileDetails} from '@amzn/meridian/file-input';
import SimpleFileInput from './SimpleFileInput';
import axios from 'axios';
import {getAppSetting} from '../../config/AppSettings';
import ValidationModal from './ValidationModal';
import Alert from '@amzn/meridian/alert';


class ConfigFileInputContainer extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            files: [],
            errors: null,
            name: props.fileName,
            pageError: false
        };
    }

    //sets state and calls back to parent component to update that state also
    setStateAndRunCallback = (val) => {
        this.setState(val, () => {
            this.props.toCallBack(this.state);
        });
    };

    failedRequest() {
        this.setState({pageError: true});
    }

    render() {
        const {files, errors, name, pageError} = this.state;
        const error = errors != null && Object.keys(errors).length > 0;

        if (pageError) {
            return (
                <Alert type={'error'}>Error loading data, try refreshing</Alert>
            );
        }

        return (
            <SimpleFileInput
                onFileAttached={async (acceptedFiles) => {
                    this.setState({files: acceptedFiles});
                    const apiUrl = getAppSetting('apiUrl');
                    const formData = new FormData();
                    formData.append(name, acceptedFiles[0], acceptedFiles[0].name);
                    axios.post(apiUrl + '/jwt/validate/' + this.props.station + '/' + name, formData, {
                        method: 'POST',
                        url: apiUrl + '/jwt/validate/' + this.props.station + '/' + name,
                        data: formData,
                    })
                        .then(response => this.setStateAndRunCallback({errors: response.data}))
                        .catch(() => this.failedRequest());
                }}
                uploadButtonLabel={'Choose File'}
                accept={['.csv', '.xlsx', '.xls']}
                uploadButtonDisabled={false}
            >
                {/*
                Meridians file input allows multiple files, so although we only allow one it is still an array
                */}
                {files.map(file => {
                    return (
                        <FileDetails
                            error={error}
                            errorMessage={'File failed validation'}
                            file={file}
                            key={file.name}
                            cancelUploadButtonLabel={`Cancel, ${file.name}`}
                            onClickCancel={() => {
                                this.setStateAndRunCallback({files: [], errors: null});
                            }}

                            uploadComplete={errors != null}
                        />
                    );
                })}
                {error ?
                    <ValidationModal errors={errors}/>
                    : null
                }
            </SimpleFileInput>
        );
    }
}

export default ConfigFileInputContainer;

type Props = {
    station: string
    fileName: string
    toCallBack: ((ComponentState) => void)
}