import React, {Ref, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import filterPropsByPrefix from '@amzn/meridian/_utils/filter-props-by-prefix';


const SimpleFileInput = (props: Props) => {
    const {children: fileDetails, onFileAttached, accept} = props;
    const onDrop = useCallback(
        acceptedFiles => {
            onFileAttached(acceptedFiles);
        },
        [onFileAttached]
    );

    const {getInputProps, open} = useDropzone({
        multiple: false,
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept,
        onDrop,
    });

    const passThroughProps = filterPropsByPrefix(props, ['data-']);

    return (
        <Row alignmentVertical="top" alignmentHorizontal={'right'}>
            <Column minWidth={0}>
                {fileDetails}
            </Column>
            <Column>
                <input {...passThroughProps} {...getInputProps()}/>
                <Row>
                    <Button
                        onClick={open}
                        label={props.uploadButtonLabel}
                        disabled={props.uploadButtonDisabled}
                        ref={props.buttonRef}
                    >
                        {props.uploadButtonLabel}
                    </Button>
                </Row>
            </Column>
        </Row>
    );
};

type Props = {
    onFileAttached: (acceptedFiles: File[]) => Promise<void>
    accept: Array<string>
    buttonRef?: Ref<unknown>
    'data-*'?: string
    uploadButtonDisabled: boolean
    uploadButtonLabel: string
    children: Array<object>
}


export default SimpleFileInput;