import Table, {TableCell, TableRow} from '@amzn/meridian/table';
import React, {ComponentState} from 'react';

class ErrorTable extends React.Component<Props, ComponentState> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const errors = this.props.errors;
        return (
            <Table headerRows={1} showDividers={true} stickyHeaderRow={true}>
                <TableRow>
                    <TableCell>Row</TableCell>
                    <TableCell>Error</TableCell>
                </TableRow>
                {/*
                Displays all errors on their own rows
                */}
                {Object.keys(errors).map(row => (
                    errors[row].map(message => (
                        <TableRow key={row + message}
                            highlightOnHover={true}>
                            <TableCell alignmentHorizontal={'center'}>{row}</TableCell>
                            <TableCell>{message}</TableCell>
                        </TableRow>
                    ))
                ))}
            </Table>
        );
    }
}

export default ErrorTable;

type Props = {
    errors: object
}