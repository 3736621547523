import React, {ComponentState} from 'react';
import Table, {TableCell, TableRow, TableSectionHeader,} from '@amzn/meridian/table';
import Input from '@amzn/meridian/input';
import Box from '@amzn/meridian/box';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import axios, {AxiosResponse} from 'axios';
import {getAppSetting} from '../../config/AppSettings';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';


class StationsTable extends React.Component<RouteComponentProps, ComponentState> {
    private readonly onChangeNameFilter: (nameFilter: string) => void;

    constructor(props) {
        super(props);
        this.state = {
            nameFilter: '',
            entries: [],
            pageError: false,
        };
        /**
         * Updates the string that Entry's; names are checked against for filtering.
         */
        this.onChangeNameFilter = nameFilter => this.setState({nameFilter});
    }

    setStations(response: AxiosResponse) {
        const entries = response.data;
        this.setState({entries: entries});
    }

    failedRequest() {
        this.setState({pageError: true});
    }

    //Fetches stations list
    componentDidMount() {
        const apiUrl = getAppSetting('apiUrl');
        axios.get(apiUrl + '/jwt/stations')
            .then((response) => {
                this.setStations(response);
            })
            .catch(() => this.failedRequest());
    }

    navigateStation = name => {
        this.props.history.push('/' + name);
    };

    render() {
        const {nameFilter, entries, pageError} = this.state;

        //sorts values, filters out empty and repeated values, and filters for search
        const filteredEntries = entries
            .sort(function (first, second) {
                const name1 = first.Station.toString();
                const name2 = second.Station.toString();
                if (name1 < name2) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .filter(function (element, index, array) {
                return element.Station.includes(nameFilter.toLocaleUpperCase())
                    && element.Station != ''
                    && (index == 0 || element.Station != array[index - 1].Station);
            });

        if (pageError) {
            return (
                <Alert type={'error'}>Error loading table, try refreshing</Alert>
            );
        }

        return (
            <Box type="outline" overflowY={'scroll'} maxHeight={window.innerHeight * .75}>
                <Table headerRows={1} showDividers={true} stickyHeaderRow={true}>
                    <TableSectionHeader>
                        <Input
                            width="50%"
                            type="text"
                            placeholder="Filter by name..."
                            value={nameFilter}
                            onChange={this.onChangeNameFilter}
                        />
                    </TableSectionHeader>
                    {this.state.entries.length === 0 ?
                        <TableRow>
                            <TableCell alignmentHorizontal={'center'}>
                                <Loader/>
                            </TableCell>
                        </TableRow>
                        :
                        filteredEntries.map(filteredEntries => (
                            <TableRow key={filteredEntries.Station}
                                onClick={() => this.navigateStation(filteredEntries.Station)}
                                highlightOnHover={true}>
                                <TableCell>{filteredEntries.Station}</TableCell>
                            </TableRow>
                        ))}
                </Table>
            </Box>
        );
    }
}

export default withRouter(StationsTable);