import React, {ComponentState} from 'react';
import Table, {TableCell, TableRow, TableSectionHeader,} from '@amzn/meridian/table';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import Link from '@amzn/meridian/link';
import axios, {AxiosResponse} from 'axios';
import {getAppSetting} from '../../config/AppSettings';
import ConfigFileInputContainer from './ConfigFileInputContainer';
import Loader from '@amzn/meridian/loader';

class ConfigTable extends React.Component<Props, ComponentState> {
    constructor(props) {
        super(props);
        const pathname = props.history.location.pathname;
        this.state = {
            downloadStatus: [
                {name: 'Address Dictionary', empty: true},
                {name: 'Cluster Definition', empty: true},
                {name: 'Configuration', empty: true},
                {name: 'Hyper Zone', empty: true},
                {name: 'Roster', empty: true},
                {name: 'Zone Definition', empty: true},
            ],
            uploadStatus: [
                {name: 'Address Dictionary', uploadable: null},
                {name: 'Cluster Definition', uploadable: null},
                {name: 'Configuration', uploadable: null},
                {name: 'Hyper Zone', uploadable: null},
                {name: 'Roster', uploadable: null},
                {name: 'Zone Definition', uploadable: null},

            ],
            loading: true,
            station: pathname.substring(pathname.lastIndexOf('/') + 1),
            pageError: false
        };
    }

    setFileList(response: AxiosResponse) {
        const downloadStatus = response.data;
        this.setState({downloadStatus: downloadStatus, loading: false});
    }

    failedRequest() {
        this.setState({pageError: true});
    }

    //When the table loads immediately call for the file list
    componentDidMount() {
        const apiUrl = getAppSetting('apiUrl');
        axios.get(apiUrl + '/jwt/file-list/' + this.state.station)
            .then((response: AxiosResponse) => {
                this.setFileList(response);
            })
            .catch(() => this.failedRequest());
    }

    //Calls backend to download a template
    getTemplate(name: string) {
        const apiUrl = getAppSetting('apiUrl');
        axios({
            url: apiUrl + '/jwt/template/' + name,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.csv');
            document.body.appendChild(link);
            link.click();
        }).catch(() => this.failedRequest());
    }

    getFile(name: string, station: string, fileType: string) {
        const apiUrl = getAppSetting('apiUrl');
        axios.get(apiUrl + '/jwt/' + fileType + '/' + station + '/' + name)
            .then(response => window.location.href = response.data.toString())
            .catch(() => this.failedRequest());
    }

    //Is called by Config File Input to set a specific files upload status
    setUpload(state) {
        const newUploadStatus = this.state.uploadStatus.map(obj => {
            //Finds the file who's status is being updated
            if (obj.name === state.name) {
                //Sets uploadable as true if its valid, false if invalid, and null if no file is attached
                if (state.files.length > 0) {
                    return {...obj, uploadable: Object.keys(state.errors).length == 0};
                } else {
                    return {...obj, uploadable: null};
                }
            }
            //retains existing file status's
            return obj;
        });
        this.setState({uploadStatus: newUploadStatus}, () => {
            this.props.toCallBack(this.state);
        });
    }

    render() {
        const {downloadStatus, station, pageError, loading} = this.state;

        if (pageError) {
            return (
                <Alert type={'error'}>Error loading table, try refreshing</Alert>
            );
        }

        return (
            <Table spacing={'medium'} showStripes={true} showDividers={true} headerRows={1} stickyHeaderRow={true} layout={'fixed'}>
                <TableSectionHeader>
                    <Row widths={['45%', 'fill', '3%']}>
                        <Text alignment={'right'}>Download</Text>
                        <Text alignment={'right'}>Upload</Text>
                        <Column/>
                    </Row>
                </TableSectionHeader>
                {downloadStatus.map(fileData => (
                    <TableRow key={fileData.name}>
                        {/*
                        Displays the filename along with its downloadable status
                        */}
                        <TableCell columnSpan={25}>
                            <Column>
                                <Row>
                                    {/*
                                    Allows user to download template by clicking on the files name
                                    */}
                                    <Tooltip position={'left'} title={'Download Template'}>
                                        <Link type={'secondary'} onClick={() => this.getTemplate(fileData.name)}>{fileData.name}</Link>
                                    </Tooltip>
                                </Row>
                                {/*
                                Displays a loading symbol while waiting on backend
                                Displays whether a file is already uploaded or not
                                */}
                                {loading ?
                                    <Loader type={'circular'} size={'small'}/> :
                                    fileData.empty ?
                                        <Alert size={'small'} type={'warning'}>No File Saved</Alert>
                                        : <Alert size={'small'} type={'success'}>Available for Download</Alert>
                                }
                            </Column>
                        </TableCell>
                        <TableCell columnSpan={30}>
                            <Row widths={['grid-4', 'grid-4']} alignmentHorizontal={'center'}>
                                {/*
                                Displays download buttons
                                Disables buttons if the file is not uploaded or still loading
                                */}
                                <Button disabled={loading|| fileData.empty}
                                    onClick={() => this.getFile(fileData.name, station, 'json')}>
                                    JSON
                                </Button>
                                <Button disabled={loading|| fileData.empty}
                                    onClick={() => this.getFile(fileData.name, station, 'csv')}>
                                    CSV
                                </Button>
                            </Row>
                        </TableCell>
                        <TableCell alignmentHorizontal={'right'} columnSpan={45}>
                            {/*
                            Handles file selection and uploading
                            */}
                            <ConfigFileInputContainer fileName={fileData.name} station={station} toCallBack={(state) => this.setUpload(state)}/>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        );
    }
}

interface Props extends RouteComponentProps {
    toCallBack: ((ComponentState) => void)
}

export default withRouter(ConfigTable);