import StationsTable from './StationsTable';
import React from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';


export default function StationsPage() {
    return (
        <div>
            <Row widths={['grid-3', 'grid-6', 'grid-3']}>
                <Column/>
                <Column alignmentVertical={'center'} heights={['grid-2', 'grid-6']} spacingInset={'200'}>
                    <Heading level={1}>Stations</Heading>
                    <StationsTable/>
                </Column>
                <Column/>
            </Row>
        </div>
    );
}