import React, {useCallback, useState} from 'react';
import Modal from '@amzn/meridian/modal';
import ErrorTable from './ErrorTable';


export default function ValidationModal(props: Props) {
    const [open, setOpen] = useState(true);
    const onClose = useCallback(() => setOpen(false), []);

    return (
        <React.Fragment>
            <Modal
                title={'File Validation Failed'}
                open={open}
                onClose={onClose}
                scrollContainer="modal"
                closeLabel="Close"
                bodySpacingInset={'none'}
                width={'grid-6'}
            >
                <ErrorTable errors={props.errors}/>
            </Modal>
        </React.Fragment>
    );
}

type Props = {
    errors: object
}
