import React, {ComponentState, PropsWithRef} from 'react';
import Table, {TableCell, TableRow,} from '@amzn/meridian/table';
import Input from '@amzn/meridian/input';
import Box from '@amzn/meridian/box';


class ABROutputTable extends React.Component<PropsWithRef<unknown>, ComponentState> {
    private readonly onChangeIDFilter: (idFilter: string) => void;
    private readonly onChangeSeverityFilter: (idFilter: string) => void;
    private readonly onChangeExceptionFilter: (idFilter: string) => void;
    private readonly onChangeMessageFilter: (idFilter: string) => void;

    constructor(props) {
        super(props);
        this.state = {
            idFilter: '',
            severityFilter: '',
            exceptionFilter: '',
            messageFilter: '',
            dataExceptions: []
        };
        /**
         * Updates the string that Entry's; names are checked against for filtering.
         */
        this.onChangeIDFilter = idFilter => this.setState({idFilter});
        this.onChangeSeverityFilter = severityFilter => this.setState({severityFilter});
        this.onChangeExceptionFilter = exceptionFilter => this.setState({exceptionFilter});
        this.onChangeMessageFilter = messageFilter => this.setState({messageFilter});
    }

    render() {
        const {idFilter, severityFilter, exceptionFilter, messageFilter, dataExceptions} = this.state;

        //sorts values and filters based on search terms
        const filteredEntries = dataExceptions
            .sort(function (first, second) {
                const name1 = first.objectId.toString() + first.severity + first.courier + first.message;
                const name2 = second.objectId.toString() + second.severity + second.courier + second.message;
                if (name1 < name2) {
                    return -1;
                } else if (name2 > name1) {
                    return 1;
                } else {
                    return 0;
                }
            })
            .filter(function (element) {
                return element.objectId.toLocaleUpperCase().includes(idFilter.toLocaleUpperCase()) &&
                    element.severity.toLocaleUpperCase().includes(severityFilter.toLocaleUpperCase()) &&
                    element.exceptionType.toLocaleUpperCase().includes(exceptionFilter.toLocaleUpperCase()) &&
                    element.message.toLocaleUpperCase().includes(messageFilter.toLocaleUpperCase());
            });

        return (
            <Box type="outline" overflowY={'scroll'} maxHeight={window.innerHeight * .65} minWidth={'100%'}>
                <Table headerRows={2} showDividers={true} fixHeaderRows={true} layout={'fixed'}>
                    <TableRow>
                        <TableCell>Tracking ID</TableCell>
                        <TableCell>Severity</TableCell>
                        <TableCell>Exception Type</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell/>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Input
                                type="text"
                                placeholder="Filter by id..."
                                value={idFilter}
                                onChange={this.onChangeIDFilter}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="text"
                                placeholder="Filter by severity..."
                                value={severityFilter}
                                onChange={this.onChangeSeverityFilter}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="text"
                                placeholder="Filter by exception type..."
                                value={exceptionFilter}
                                onChange={this.onChangeExceptionFilter}
                            />
                        </TableCell>
                        <TableCell columnSpan={2}>
                            <Input
                                type="text"
                                placeholder="Filter by message..."
                                value={messageFilter}
                                onChange={this.onChangeMessageFilter}
                            />
                        </TableCell>
                    </TableRow>
                    {filteredEntries.map(filteredEntries => (
                        <TableRow key={filteredEntries.objectId + filteredEntries.message}
                            highlightOnHover={true}>
                            <TableCell>{filteredEntries.objectId}</TableCell>
                            <TableCell>{filteredEntries.severity}</TableCell>
                            <TableCell>{filteredEntries.exceptionType}</TableCell>
                            <TableCell columnSpan={2}>{filteredEntries.message}</TableCell>

                        </TableRow>
                    ))}
                </Table>
            </Box>
        );
    }
}

export default ABROutputTable;