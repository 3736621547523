import Button from '@amzn/meridian/button';
import React, {ComponentState} from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Heading from '@amzn/meridian/heading';
import Theme from '@amzn/meridian/theme';
import ConfigTable from './ConfigTable';
import orangeLightTokens from '@amzn/meridian-tokens/theme/orange-light';
import ButtonModal from './ButtonModal';
import {getAppSetting} from '../../config/AppSettings';
import axios from 'axios';
import ValidationModal from './ValidationModal';
import Alert from '@amzn/meridian/alert';
import Loader from '@amzn/meridian/loader';

class FileManagerPage extends React.Component<RouteComponentProps, ComponentState> {
    constructor(props) {
        super(props);
        const pathname = props.history.location.pathname;
        this.state = {
            station: pathname.substring(pathname.lastIndexOf('/') + 1),
            errors: null,
            uploadStatus: [
                {name: 'Address Dictionary', uploadable: null},
                {name: 'Cluster Definition', uploadable: null},
                {name: 'Configuration', uploadable: null},
                {name: 'Hyper Zone', uploadable: null},
                {name: 'Roster', uploadable: null},
                {name: 'Zone Definition', uploadable: null},
            ],
            uploadEnabled: false,
            validating: false
        };
    }

    //Uploads files for cross file validation and returns results
    async confirmUpload() {
        this.setState({validating: true});
        const apiUrl = getAppSetting('apiUrl');
        const json = JSON.stringify(this.state.uploadStatus);
        const {data: errors} = await axios.post(apiUrl + '/jwt/upload/' + this.state.station, json, {
            method: 'POST',
            url: apiUrl + '/jwt/upload/' + this.state.station,
            data: json,
            headers: {'Content-Type': 'text/csv'}
        });
        this.setState({errors: errors, validating: false});
    }

    //Is called by the config table to allow or deny the use of the upload button
    uploadEnabler(childState) {
        this.setState({uploadStatus: childState.uploadStatus});
        const temp = childState.uploadStatus;
        //Only allow uploads if none of the files have errors and at least one of them has a validated file
        if (temp.filter((item) => {
            return item.uploadable === false;
        }).length === 0 &&
            (temp.filter((item) => {
                return item.uploadable === null;
            }).length !== 6)) {
            this.setState({uploadEnabled: true});
        } else {
            this.setState({uploadEnabled: false});
        }
    }

    render() {
        const {errors, uploadEnabled, validating} = this.state;
        return (
            <div>
                <Row widths={['grid-3', 'grid-6', 'grid-3']}>
                    <Column/>
                    <Column alignmentVertical={'center'} heights={['grid-3', 'fill', 'grid-2']} spacingInset={'200'}
                        maxHeight={window.innerHeight * .9}>
                        {/*
                        Heading row
                        */}
                        <Row>
                            <Heading level={1}>Config</Heading>
                        </Row>
                        {/*
                        Table displaying all files
                        */}
                        <Box type={'outline'} maxHeight={window.innerHeight * .75} overflowY={'auto'}>
                            <ConfigTable toCallBack={(childState) => this.uploadEnabler(childState)}/>
                        </Box>
                        <Row widths={['grid-6', 'grid-6']}>
                            {/*
                            Button to exit page
                            */}
                            <Column alignmentHorizontal={'left'}>
                                <Theme tokens={orangeLightTokens}>
                                    <ButtonModal button={'Cancel'} title={'Warning'}
                                        message={'Are you sure you want to exit without saving'}/>
                                </Theme>
                            </Column>
                            <Column alignmentHorizontal={'right'}>
                                <Row>
                                    {/*
                                    Displays a loading symbol if we are validating
                                    Displays errors if we failed validation
                                    Displays a success message if we passed validation
                                    Otherwise shows nothing
                                    */}
                                    {validating ? (<Loader size={'medium'}/>) :
                                        errors !== null ? (
                                            Object.keys(errors).length > 0 ? (
                                                <div>
                                                    <Alert type={'error'} size={'small'}>Failed cross-file validation</Alert>
                                                    <ValidationModal errors={errors}/>
                                                </div>
                                            ) : <Alert type={'success'} size={'small'}>Successfully uploaded files</Alert>
                                        ) : null
                                    }
                                    {/*
                                    Upload button
                                    */}
                                    <Theme tokens={orangeLightTokens}>
                                        <Button onClick={() => this.confirmUpload()} disabled={!uploadEnabled}>
                                            Upload Files
                                        </Button>
                                    </Theme>
                                </Row>
                            </Column>
                        </Row>
                    </Column>
                    <Column/>
                </Row>
            </div>
        );
    }
}

export default withRouter(FileManagerPage);